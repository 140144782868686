/* prefixed by https://autoprefixer.github.io (PostCSS: v7.0.26, autoprefixer: v9.7.3) */

body {
	background: #eee;
}

.header {
	padding: 50px 50px 200px 50px;
	background: #2c2f79;
	text-align: center;
	border-bottom: 15px solid #e1c721;
}

.header img {
	height: 140px;
	width: auto;
}

.flags{
	top: -60px;
	right: 30px;
	position: absolute;
}

.flags .flag{
	display:-webkit-inline-box;
	display:-ms-inline-flexbox;
	display:inline-flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	padding: 6px 9px;
	margin: 0 10px;
	color: #FFF;
	cursor: pointer;
	border-radius:3px;
}

.flags .flag:hover,
.flags .flag.active{
	background: #232661;
}

.flags img{
	width:auto;
	height: 20px;
	margin-right:10px;
}

.main {
	margin-top: -150px;
	padding-bottom: 50px;
	position: relative;
}

.card-body.rows {
	padding: 0 15px;
}

.card-body > .row {
	padding: 30px 15px;
}

.card-body > .row + .row {
	border-top: 1px solid rgba(0, 0, 0, 0.125);
	margin-top: -1px;
}

.step {
	width: 1rem;
	font-size: 0.8rem;
	text-align: center;
}

.spinner {
	width: 100vw;
	height: 100vh;
	position: fixed;
	z-index: 9999;
	top: 0;
	left: 0;
	background: rgba(255, 255, 255, 0.7);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	opacity: 0;
	-webkit-transition: opacity 0.4s linear;
	-o-transition: opacity 0.4s linear;
	transition: opacity 0.4s linear;
	text-align: center;
}
.spinner h6 {
	margin: 0;
}
.spinner.show {
	opacity: 1;
}

.success {
	padding: 8vw;
	text-align: center;
}

@media (max-width: 575px) {
	[class^="col-"] + [class^="col-"] {
		margin-top: 1rem;
	}
}